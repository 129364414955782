<div *ngIf="!specialImage" class="header-image-wrapper" [dir]="(settings.rtl) ? 'rtl' : 'ltr'" [class.fullscreen]="fullscreen" [ngClass]="'toolbar-'+settings.toolbar">
    <div class="bg" [style.background-image]="bgImage" [class.bg-anime]="bgImageAnimate" style="background-position: initial;"></div>
    <div class="mask" style="background: #000"></div>   
    <div class="header-image-content" fxLayout="column" fxLayoutAlign="center center" [class.offset-bottom]="contentOffsetToTop" [class.home-page]="isHomePage"  [style.min-height.px]="contentMinHeight">
      <img src="assets/images/others/logo.jpg" style="margin: 1rem 0; height: 250px; border-radius: 5px;">
      <h1 *ngIf="title" class="title" style="font-family: sans-serif; text-transform: uppercase;">{{title}}</h1>
    </div>
</div>

<div *ngIf="specialImage" id="carouselExample" class="carousel slide" style="position: relative; top: -5rem; margin-bottom: -5rem;">
  <div class="carousel-inner">
    <img [src]="backgroundImage" class="d-block w-100">
  </div>
</div>