import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'Home', '/', null, null, false, 0),
    //new Menu (2, 'Tutorial', '/tutorial', null, null, false, 0), 
    new Menu (2, 'My Test Request', '/study-group', null, null, false, 0), 
    new Menu (70, 'My Account', '/account', null, null, false, 0),  
    new Menu (90, 'Log Out', '/logout', null, null, false, 0)
]

export const verticalMenuItems = [ 
    new Menu (1, 'Home', '/', null, null, false, 0),
    //new Menu (2, 'Tutorial', '/tutorial', null, null, false, 0), 
    new Menu (2, 'My Test Request', '/study-group', null, null, false, 0), 
    new Menu (70, 'My Account', '/account', null, null, false, 0)
]